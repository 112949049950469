<template>
    <modal ref="modalEditarPregunta" titulo="Editar Pregunta" no-aceptar adicional="Guardar" @adicional="guardarPreguntaFrecuenteHija">
        <ValidationObserver ref="formHija">
                <div class="row mx-0 j-center">
                    <div class="col-9 mb-1 mt-2">
                        <p class="label pl-3">
                            Nombre de la pregunta
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required|max:80" name="titulo">
                            <el-input v-model="hija.titulo" class="w-100" placeholder="Nombre" maxlength="80" show-word-limit size="small" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-9 mt-2 mb-2">
                        <p class="label pl-3">
                            Respuesta
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required|max:1000" name="respuesta">
                            <el-input v-model="hija.texto" placeholder="Respuesta"  type="textarea" :rows="5" maxlength="1000" show-word-limit class="w-100" size="small" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Configuracion from '@/services/configuracion'
export default {
    data(){
        return {
            hija: {
                id: null,
                titulo: '',
                texto: '',
            },
        }
    },
    methods: {
        toggle(hija){
            this.hija = hija
            this.$refs.modalEditarPregunta.toggle();
        },
        async guardarPreguntaFrecuenteHija(){
            try {
                
                let validate = await this.$refs.formHija.validate()
                if(!validate) return this.notificacion('','Campos incompletos')

                let params = {
                    id_faq: this.hija.id,
                    titulo: this.hija.titulo,
                    respuesta: this.hija.texto
                }
                
                const { data } = await Configuracion.editarFaq(params)

                this.notificacion('','Pregunta editada correctamente','success')
                this.$refs.modalEditarPregunta.toggle();
                
                this.$emit('actualizar')
            } catch (error) {
                this.error_catch(error)
            }
        },

    }
}
</script>
<style lang="scss" scoped>
.label{
    font-size: 12px;
    color: var(--text-general);
}
</style>